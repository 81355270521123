// 场景
<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 场景管理配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <h3 class="page-title">场景管理配置</h3>
      <el-form :inline="true"
               :model="formInline"
               class="demo-form-inline">
        <el-form-item label="条件:">
          <el-input v-model="query.name "
                    placeholder="可搜索ID、场景名称"
                    class="handle-input mr10 w100"></el-input>
        </el-form-item>
        <el-form-item label="行业：">
          <el-select v-model="query.induId"
                     placeholder="请选择"
                     class="handle-select mr10">
            <el-option key="bbk"
                       label="请选择"
                       value=""></el-option>
            <el-option :key="item.id"
                       v-for="(item) in allIndustry"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职业：">
          <el-select v-model="query.occuId"
                     placeholder="请选择"
                     class="handle-select mr10">
            <el-option key="bbk"
                       label="请选择"
                       value=""></el-option>
            <el-option :key="item.id"
                       v-for="(item) in allOcc2"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="职位：">
          <el-select v-model="query.occuId"
                     placeholder="请选择"
                     class="handle-select mr10">
            <el-option key="bbk"
                       label="请选择"
                       value=""></el-option>
            <el-option :key="item.id"
                       v-for="(item) in allPosition"
                       :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary ml10"
                     icon="el-icon-search"
                     @click="searchList">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
        <el-table-column prop="id"
                         label="场景ID"
                         width="120"
                         align="center">
          <template #default="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sort"
                         label="排序"
                         width="120"
                         align="center">
        </el-table-column>

        <el-table-column prop="id"
                         label="场景名称"
                         align="center">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="行业"
                         align="center">
          <template #default="scope">
            <span>{{ scope.row.induName ? scope.row.induName: "不限" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="职业"
                         align="center">
          <template #default="scope">
            <span>{{ scope.row.occuName? scope.row.occuName: "不限"  }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="职位"
                         align="center">
          <template #default="scope">
            <span>{{ scope.row.postName ? scope.row.postName: "不限" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="360"
                         align="center">
          <template #default="scope">
            <el-button type="text"
                       icon="el-icon-edit"
                       @click="handleEdit(scope.$index, scope.row)">修改</el-button>
            <el-button type="text"
                       icon="el-icon-circle-close"
                       v-if="scope.row.isEnable"
                       class="red"
                       @click="changeStatus(scope.row)">禁用</el-button>
            <el-button type="text"
                       icon="el-icon-circle-check"
                       v-else
                       @click="changeStatus( scope.row)">启用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-title"
           @click="addGoods">+添加场景</div>
      <div class="page"
           v-if="pageTotal>20">
        <el-pagination background
                       layout=" prev, pager, next"
                       :current-page="query.pageIndex"
                       :page-size="20"
                       :total="pageTotal"
                       @current-change="listChange"></el-pagination>
      </div>

      <!-- 修改信息弹窗开始  -->
      <el-dialog v-model="showDialog"
                 :title="addType==1?'添加场景':'修改场景'"
                 width="40%"
                 center>
        <el-form ref="formData"
                 label-width="120px">
          <el-form-item label="排序:"
                        required>
            <el-input v-model.trim="activeData.sort"
                      type="Number"
                      placeholder="数字，必填，越小越靠前"
                      maxlength="20"></el-input>
          </el-form-item>

          <el-form-item label="场景名称:"
                        required>
            <el-input v-model.trim="activeData.name"
                      placeholder="中英文限字20，必填"
                      maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="行业:"
                        required>
            <el-radio v-model="query.tradeFlag"
                      :label="parseInt(0)">不限行业</el-radio>
            <el-radio v-model="query.tradeFlag"
                      :label="parseInt(1)">指定行业</el-radio>
          </el-form-item>
          <div class="trade"
               v-if="query.tradeFlag== '1'">
            <el-button type="primary"
                       @click="showLabelDialog('trade',0)">添加行业</el-button>
            <div class="trade-table"
                 v-if="induArray.length > 0">
              <el-table :data="induArray"
                        border
                        class="table"
                        ref="multipleTable"
                        header-cell-class-name="table-header">
                <el-table-column prop="name"
                                 label="行业名称"
                                 align="center">
                </el-table-column>
                <el-table-column label="操作"
                                 width="360"
                                 align="center">
                  <template #default="scope">
                    <el-button type="text"
                               icon="el-icon-edit"
                               @click="showLabelDialog('trade',1)">修改</el-button>
                    <el-button type="text"
                               icon="el-icon-circle-close"
                               class="red"
                               @click="dealData('trade',scope)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
          <el-form-item label="职业:"
                        required>
            <el-radio v-model="query.occFlag"
                      :label="parseInt(0)">不限职业</el-radio>
            <el-radio v-model="query.occFlag"
                      :label="parseInt(1)">指定职业</el-radio>
          </el-form-item>
          <div class="occ"
               v-if="query.occFlag== '1'">
            <el-button type="primary"
                       @click="showLabelDialog('occ',0)">添加职业</el-button>
            <div class="occ-table"
                 v-if="allOcc && allOcc.length  > 0">
              <el-table :data="allOcc"
                        border
                        class="table"
                        ref="multipleTable"
                        header-cell-class-name="table-header">
                <el-table-column prop="occuName"
                                 label="职业名称"
                                 align="center">
                </el-table-column>
                <el-table-column prop="posiName"
                                 label="职位名称"
                                 align="center">
                </el-table-column>
                <el-table-column label="操作"
                                 width="360"
                                 align="center">
                  <template #default="scope">
                    <el-button type="text"
                               icon="el-icon-edit"
                               @click="showLabelDialog('occ',1)">修改</el-button>
                    <el-button type="text"
                               icon="el-icon-circle-close"
                               class="red"
                               @click="dealData('occ',scope)">删除</el-button>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>

          <el-form-item label="适用范围:"
                        required>
            <el-input v-model.trim="activeData.scope"
                      type="textarea"
                      show-word-limit
                      placeholder="中英文数字100字以内"
                      maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="场景介绍:"
                        required>
            <el-input v-model.trim="activeData.describe"
                      type="textarea"
                      show-word-limit
                      placeholder="中英文数字100字以内"
                      maxlength="300"></el-input>
          </el-form-item>

        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveEdit "
                       type="primary">{{addType ==1 ?'保存':'修改'}}</el-button>
            <el-button @click="showDialog = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 修改信息弹框结束 -->
      <!-- 选择弹框开始 -->
      <el-dialog v-model="changeDialog"
                 :title="eaditTitle"
                 class="label-main"
                 width="40%"
                 center>
        <div class="dialog">
          <div class="select"
               v-if="type=='occ'">
            <el-select v-model="fatherName"
                       placeholder="请选择职业">
              <el-option v-for="(item,i) in fatherList"
                         :key="i"
                         :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-button type="primary"
                       @click="getPosiList"
                       class="search-label">搜索</el-button>
            <p style="color: red;paddingTop: 10px">请先选择职业进行筛选数据</p>
          </div>
          <div class="dialog-top"
               v-else>
            <el-input v-model.trim="change.name"
                      placeholder="搜索ID，名称"></el-input>
            <el-button type="primary"
                       class="search-label"
                       @click="getTradeData">搜索</el-button>
          </div>
          <p v-if="dialogData.lengtn <= 0">暂无数据</p>
          <div class="dialog-main">
            <ul class="label">
              <li class="label-item"
                  @click="addTag(item)"
                  v-for="(item,i) in dialogData "
                  :key="i">ID: {{item.id}}--名称: {{item.name}}</li>
            </ul>
            <div class="page"
                 v-if="dialogTotal>6">
              <el-pagination background
                             layout=" prev, pager, next"
                             :current-page="pageNum"
                             :page-size="6"
                             :total="dialogTotal"
                             @current-change="pageChange"></el-pagination>
            </div>
          </div>
          <div class="dialog-bottom">
            <div class="bottom-top">
              已选: <span style="color: red"
                    v-if="tags.length <= 0"> 暂未选中数据</span>
              <div class="bottom-box"
                   v-else>
                <el-tag v-for="(tag,i) in tags"
                        @close="closeTag(i)"
                        class="tag"
                        :key="tag.name"
                        closable>
                  {{type =="occ"?  tag.occuName +"--"+tag.posiName : tag.name  }}
                </el-tag>
              </div>
            </div>
            <div class="bottom-btn">
              <el-button @click="changeDialog = false">取消</el-button>
              <el-button class="lable-btn"
                         @click="okDialog "
                         type="primary">选好了</el-button>
            </div>

          </div>
        </div>
      </el-dialog>

      <!-- 选择弹窗结束 -->
    </div>
  </div>
</template>

<script>
import {
  getPayList, savePayList, getSenceArray, getSenceStatus, getAllIndustry, getAllOcc, getAllPosition,
  getSenceItem, getTradeList, GetAllPosiList, getAddSenceList
} from "../../api/index";
var md5 = require("@/assets/js/md5.min.js");

export default {
  name: "basetable",
  data () {
    return {
      addType: 1, //1添加2修改
      eaditTitle: "修改场景",
      query: {
        name: "",
        induId: "",
        occuId: "",
        pageIndex: 1,
        pageSize: 20
      },
      activeData: {
        id: "",
        induIds: "",
        isEnable: true,
        name: "",
        occuPositList: [],
        sort: 0
      },
      induArray: [], // 已经选中的行业
      allIndustry: [],// 行业
      allOcc: [], // 职业
      allOcc2: [],
      allPosition: [], // 职位
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      writeFlag: false, // 行编辑状态
      showDialog: false,
      fatherId: "",
      fatherList: [
        {
          name: " 职位id",
          id: 1
        },
        {
          name: " 职位id2",
          id: 2
        }
      ],
      changeDialog: false,
      fatherName: "",// 职业
      change: {
        name: "",
      },
      dialogData: [],
      dialogTotal: 0,
      pageIndex: 1, // 弹窗分页
      tags: [],




    };
  },
  created () {
    this.getData();
    this.getLabels();
    this.getPayData();
    this.getTradeData();
  },
  methods: {
    // 搜索列表
    searchList () {
      this.getData();
    },
    // 删除数据
    dealData (type, scope) {
      let i = scope.$index
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          if (type == "trade") {
            this.induArray.splice(i, 1);
          } else if (type == "occ") {
            this.allOcc.splice(i, 1);
          }
        })
        .catch(() => { });

      console.log(type, scope);
    },

    // 选择标签弹窗
    showLabelDialog (type, flag) {
      this.type = type;
      if (type == "trade") {
        this.getTradeData();
        this.tags = this.induArray.slice(0);
        if (flag == 0) {
          this.eaditTitle = "添加行业";
        } else {
          this.eaditTitle = "修改行业";
        }
        this.pageNum = 1;

      } else if (type == "occ") {
        this.dialogData = [];
        this.dialogTotal = 0;
        this.$forceUpdate();
        this.getAllOccList();// 先获取职业
        this.tags = this.allOcc.slice(0);
        if (flag == 0) {
          this.eaditTitle = "添加职业";
        } else {
          this.eaditTitle = "修改职业";
        }
      }
      this.changeDialog = true;
    },
    // 添加标签
    addTag (item) {
      if (this.type == "occ") {
        let occList = this.fatherList.filter(item => {
          return item.id == this.fatherName
        })
        let obj = {
          docId: this.id,
          id: this.fatherName,
          occuId: this.fatherName,
          occuName: occList[0].name,
          posiId: item.id,
          posiName: item.name

        }
        let list = this.tags.filter(c => {
          return c.posiId === obj.posiId
        })
        if (list.length > 0) {
          this.$notify.warning({
            title: "提示",
            message: "数据已经存在"
          });
          return false;
        }


        this.tags.push(obj);

      } else {
        let list = this.tags.filter(c => {
          return c.id === item.id
        })
        if (list.length > 0) {
          this.$notify.warning({
            title: "提示",
            message: "数据已经存在"
          });
          return false;
        }
        this.tags.push(item);
      }
    },
    // 选择好了
    // 选择好了
    okDialog () {
      let type = this.type;
      if (type == "trade") {
        this.induArray = this.tags
      } else if (type == "occ") {
        this.allOcc = this.tags;
      }
      this.changeDialog = false;
    },


    //选择框- 获取所有行业
    getTradeData () {
      let params = {
        name: this.change.name,     //数字则查id，文字则查名称
        pageIndex: this.pageNum,
        pageSize: 6,
      }
      getTradeList(params).then(res => {
        if (res.code === 0) {
          this.dialogData = res.data.data;
          this.dialogData = this.dialogData.filter(item => {
            return item.enable == true;
          })
          this.dialogTotal = res.data.total;
        }
        console.log(res);
      })
    },
    // 所有职业
    getAllOccList () {
      getAllOcc().then(res => {
        if (res.code == 0) {
          this.fatherList = res.data;
        }
      });
    },
    // 所有职业
    getPosiList () {
      let params = {
        id: this.fatherName,
        isEnable: true,
      }
      GetAllPosiList(params).then(res => {
        if (res.code == 0) {
          this.dialogData = res.data;
          if (this.dialogData != null && this.dialogData.length > 0) {
            this.dialogData = this.dialogData.filter(item => {
              return item.isEnable == true;
            })
          } else {
            this.dialogData = [];
          }
        }
      })

    },



    // 获取下拉框数据
    getLabels () {
      getAllIndustry().then(res => {
        if (res.code == 0) {
          this.allIndustry = res.data;
        }
      });
      getAllOcc().then(res => {
        if (res.code == 0) {
          this.allOcc2 = res.data;
        }
      });
      getAllPosition().then(res => {
        if (res.code == 0) {
          this.allPosition = res.data;
        }
      });

    },

    addData (type) {
      //添加行业
      if (type == 1) {
        this.query.industryList.push({
          id: ""
        });
      } else {
        //添加职业
        this.query.professionList.push({
          id: ""
        });
      }
    },
    delData (type, i) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //删除行业
          type == 1 && this.query.professionList.splice(i, 1);
          type == 2 && this.query.professionList.splice(i, 1);
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    changeStatus (row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let id = row.id;
          let params = {
            id
          }
          getSenceStatus(params).then(res => {
            if (res.code == 0) {
              this.$notify.success({
                title: "提示",
                message: "操作成功"
              });
              this.getData();
            } else {
              this.$notify.warning({
                title: "提示",
                message: "操作失败"
              });
            }
          })
        })
        .catch(() => { });
    },
    // 获取列表
    getPayData () {
      let params = {
        ccode: "测试渠道",
        pcode: "PPT_QY",
        uuid: "uuxx12021xee",
        sign: "0BB930E4672BBDE054D0E238FCF63CDE"
      };
      getPayList(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          let list = [];
          list.push(res.data.gold);
          list.push(res.data.lifeLong);
          list.push(res.data.normal);
          this.tableData = list;
        }
      });
    },
    // 保存列表
    savePayData () {
      this.tableData.map(item => {
        let n = String(item.price).indexOf(".");
        if (n > -1) {
          item.price =
            String(item.price).substr(0, n) + String(item.price).substr(n, 3);
        }
        let m = String(item.rawPrice).indexOf(".");
        if (m > -1) {
          item.rawPrice =
            String(item.rawPrice).substr(0, m) +
            String(item.rawPrice).substr(m, 3);
        }
      });

      let normal = this.tableData[2];
      let gold = this.tableData[0];
      let lifeLong = this.tableData[1];
      let priceList = this.tableData.filter(item => {
        return (
          Number(item.price) < 0.01 ||
          Number(item.price) > 9999 ||
          item.price == ""
        );
      });
      if (priceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "实付价格范围在0.01~9999之间,不能为空"
        });
        return false;
      }

      let rawPriceList = this.tableData.filter(item => {
        return (
          Number(item.rawPrice) < 0.01 ||
          Number(item.rawPrice) > 9999 ||
          item.rawPrice == ""
        );
      });
      console.log(rawPriceList);
      if (rawPriceList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "原始价格范围在0.01~9999之间"
        });
        return false;
      }

      let downCount = this.tableData.filter(item => {
        console.log("====================================");
        console.log(Number(item.downloadTime) < 0, item.downloadTime === "");
        console.log("====================================");
        return Number(item.downloadTime) < 0 || item.downloadTime === "";
      });
      if (downCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "下载总次数大于等于0,不能为空"
        });
        return false;
      }

      let pptDownCount = this.tableData.filter(item => {
        return Number(item.pptDailyTime) < 0 || item.pptDailyTime === "";
      });
      if (pptDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "PPT次数大于等于0，不能为空"
        });
        return false;
      }

      let wordDownCount = this.tableData.filter(item => {
        return Number(item.wordDailyTime) < 0 || item.wordDailyTime === "";
      });
      if (wordDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Word次数大于等于0,不能为空"
        });
        return false;
      }

      let excelDownCount = this.tableData.filter(item => {
        return Number(item.excelDailyTime) < 0 || item.excelDailyTime === "";
      });
      if (excelDownCount.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "Excel次数大于等于0,不能为空"
        });
        return false;
      }
      let sortList = this.tableData.filter(item => {
        return (
          item.sort === "" || Number(item.sort) < 0 || item.name.length <= 0
        );
      });

      if (sortList.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "排序和套餐名称为必填项"
        });
        return false;
      } else {
        this.tableData.map(item => {
          item.writeFlag = false;
        });
      }

      let goldStatus = String(gold.enable).replace(
        String(gold.enable)[0],
        String(gold.enable)[0].toUpperCase()
      );
      let lifeLongStatus = String(lifeLong.enable).replace(
        String(lifeLong.enable)[0],
        String(lifeLong.enable)[0].toUpperCase()
      );
      let normalStatus = String(normal.enable).replace(
        String(normal.enable)[0],
        String(normal.enable)[0].toUpperCase()
      );

      let str =
        "1bca15648633405ab86330d0d97aceb6" +
        gold.downloadTime +
        goldStatus +
        gold.excelDailyTime +
        gold.memberType +
        gold.name +
        gold.packageName +
        gold.pptDailyTime +
        gold.price +
        gold.rawPrice +
        gold.sort +
        gold.wordDailyTime +
        lifeLong.downloadTime +
        lifeLongStatus +
        lifeLong.excelDailyTime +
        lifeLong.memberType +
        lifeLong.name +
        lifeLong.packageName +
        lifeLong.pptDailyTime +
        lifeLong.price +
        lifeLong.rawPrice +
        lifeLong.sort +
        lifeLong.wordDailyTime +
        normal.downloadTime +
        normalStatus +
        normal.excelDailyTime +
        normal.memberType +
        normal.name +
        normal.packageName +
        normal.pptDailyTime +
        normal.price +
        normal.rawPrice +
        normal.sort +
        normal.wordDailyTime;

      let sign = md5(str).toUpperCase();

      let params = {
        normal,
        gold,
        lifeLong,
        sign
      };
      savePayList(params).then(res => {
        console.log("====================================");
        console.log(res);
        console.log("====================================");
        if (res.code === 0) {
          this.$notify.success({
            title: "提示",
            message: "保存成功"
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.message
          });
        }
      });
    },
    checkNumTwo (e) {
      // 通过正则过滤小数点后两位
      console.log(e);
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
    },

    //禁用
    DisableRow () {
      this.$confirm("将禁用该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "禁用成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消禁用"
          });
        });
    },
    // 启用
    enableRow () {
      this.$confirm("将启用该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "启用成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消启用"
          });
        });
    },
    //删除
    delRow () {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    handleRemove (file) {
      console.log(file);
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload (file) {
      console.log(file);
    },
    // 获取场景列表
    getData () {
      let params = {
        name: this.query.name,
        induId: this.query.induId,
        occuId: this.query.occuId,
        pageIndex: this.query.pageIndex,
        pageSize: 20
      };
      getSenceArray(params).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        }
      })

    },
    // 触发搜索按钮
    handleSearch () {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete (index) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => { });
    },
    // 多选操作
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    delAllSelection () {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 修改数据
    handleEdit (index, row) {
      this.addType = 0;
      this.induArray = [];
      this.id = -1;
      getSenceItem({ id: row.id }).then(res => {
        if (res.code == 0) {
          this.activeData = res.data;
          this.showDialog = true;
          // 行业匹配开始
          let induList = [];
          if (this.activeData.induIds) {
            induList = this.activeData.induIds.split(",");
            //   所有行业
            this.allIndustry.map(item => {
              induList.map(c => {
                if (item.id == c) {
                  this.induArray.push(item);
                }
              })
            });
            this.activeData.induIds.length > 0 ? this.query.tradeFlag = 1 : this.query.tradeFlag = 0;
          } else {
            this.query.tradeFlag = 0;
          }
          // 行业匹配结束
          // 职业匹配开始
          if (this.activeData.occuPositList) {
            console.log(11);
            this.activeData.occuPositList.length <= 0 ? this.query.occFlag = 0 : this.query.occFlag = 1;
          } else {
            console.log(12);
            this.query.occFlag = 0;
          }
          this.allOcc = this.activeData.occuPositList || [];// 职位数组

          // 职业匹配结束


          // 
          console.log(index, this.activeData, "修改数据");
        }
      })
    },
    addGoods () {
      this.activeData.id = "";
      this.addType = 1;
      this.activeData.sort = "";
      this.activeData.describe = "";
      this.activeData.scope = "";
      this.activeData.name = "";
      this.query.tradeFlag = 0;
      this.query.occFlag = 0;
      this.allOcc = [];
      this.induArray = [];
      console.log(this.allOcc, " 长度");
      this.showDialog = true;
    },
    handleSave (index, row) {
      console.log(row);
      // row.writeFlag = false;
      this.savePayData();
    },
    // 删除tag
    closeTag (i) {
      this.tags.splice(i, 1);
    },
    // 保存编辑
    saveEdit () {
      if (this.activeData.sort.length <= 0) {
        this.$message.warning("请输入排序,必填项");
        return false;
      } else {
        this.activeData.sort = Number(this.activeData.sort);
      }

      if (this.activeData.name.length == 0) {
        this.$message.warning("请输入场景名称");
        return false;
      }
      let induIds = [];
      this.induArray.map(item => {
        induIds.push(item.id)
      });
      this.activeData.induIds = induIds.join(",");
      console.log(this.allOcc, " 职位职位 ");
      this.activeData.occuPositList = this.allOcc;
      console.log(this.activeData, "保存");
      // 行业职业不限 
      if (this.query.occFlag == 0) {
        this.activeData.occuPositList = [];
      }
      if (this.query.tradeFlag == 0) {
        this.activeData.induIds = "";
      }
      if (this.activeData.scope == null || this.activeData.scope == undefined || this.activeData.scope.length <= 0) {
        this.$message.warning("请输入适用范围,必填项");
        return false;
      }
      if (this.activeData.describe == null || this.activeData.describe == undefined || this.activeData.describe.length <= 0) {
        this.$message.warning("请输入场景介绍,必填项");
        return false;
      }

      let params = this.activeData;
      getAddSenceList(params).then(res => {
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "操作成功"
          });
          this.showDialog = false;
          this.getData();
        } else {
          this.$notify.warning({
            title: "提示",
            message: "操作失败"
          });
        }
      })




    },
    listChange (val) {
      this.query.pageIndex = val;
      this.getData();

    },
    // 分页导航
    pageChange (val) {
      console.log(val);
      this.pageNum = val;
      this.getTradeData();
    },
    handlePageChange (val) {
      this.$set(this.query, "pageIndex", val);
      this.getData();
    }
  }
};
</script>

<style scoped lang='scss'>
.page-title {
  margin-bottom: 10px;
}
.handle-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.container {
  position: relative;
  top: 0;
  left: 0;
}
.add-title {
  margin-top: 8px;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  // border: 1px solid #409eff;
  background: #409eff;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.save-good {
  border: 1px solid #67c23a;
  background: #67c23a;
  color: #fff;
}
.del-btn {
  margin-left: 20px;
}
.dialog {
  .select {
    text-align: center;
  }
  .search-label {
    margin-left: 10px;
  }
  .dialog-top {
    display: flex;
  }
  .dialog-main {
    padding: 20px;
    .label {
      list-style: none;
      .label-item {
        line-height: 40px;
        text-align: center;
        background-color: #eee;
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          background-color: #ccc;
        }
      }
    }
    .page {
      width: 430px;
      margin: 0 auto;
    }
  }
  .dialog-bottom {
    padding-right: 20px;
    .bottom-box {
      display: inline-block;
      .tag {
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }

    .bottom-btn {
      padding: 20px;
      text-align: center;
      .label-btn {
        margin-left: 20px;
      }
    }
  }
}
</style>


